@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~owl.carousel/dist/assets/owl.theme.default.css";

#landing {
  background-image: url("./assets/Images/image\ 3.png");
  background-position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  overflow: hidden;
  height: 100vh;
}

#head {
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}

#ic {
  background-color: #F5900D;
  border-radius: 100%;
  color: white;
}

#para {
  margin-top: 1em;
  font-weight: bolder;
  font-stretch: extra-expanded;
}

#para span {
  color: #CCCCCC
}

#pin {
  background-color: #EC8B0D;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  position: absolute;
  top: 64%;
  left: 95%;
  transform: translate(-20%, -50%);
}



#apply {
  background-color: #F5900D;
}

#app {
  background-color: #CCCCCC
}


#navbarSupportedContent ul li #button {
  border-style: solid;
  border-color: #F5900D;
  color: #F5900D;
  font-weight: bold;
  border-radius: 20px;

}

#navbarSupportedContent ul li #button:hover {
  background-color: #F5900D;
  color: white;
}

#footer {
  border-top: #F5900D;
  border-style: solid;
}

#footer {
  background-color: #CCCCCC;
  color: black;
}

#hello {
  font-size: larger;
  font-weight: bolder;

}

#section {
  background-color: black;
  color: white;

}

.porthead {
  background-color: #E9E9E9;
}



#location {
  background-color: #E9E9E9;
}

#discover {
  background-color: #CCCCCC;
  padding: 3em;
}

#discoverShadow:hover {
  box-shadow: 2px 2px 2px 2px #FFE8AA;
}

.apply {
  border-radius: 20px;
  color: black;
  text-decoration: none;
}

.apply:hover {
  text-decoration: none;
}

.apply:hover {
  color: #F5900D;
}

.open {
  color: gray;
  font-size: 15px;
  font-weight: bold;
}

.next {
  font-weight: bolder;
}

.dot {
  color: #F5900D;
  font-weight: bolder;
  font-size: 3em;
}

#pink {
  background-color: #EC8B0D;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-20%, -50%);
  z-index: 2;
}

#colored {
  background-color: lightgray;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-80%, -50%);
  z-Index: 1
}

#scroll {
  background-color: #F5900D;
  color: white;
  border-radius: 10px;
  padding: 6px;
}






.jobDetails p i {
  color: #EC8B0D;
  margin-right: 3px;
}

#previous p i {
  background-color: #CCCCCC;
  border-radius: 100%;
  padding: 10px;
  margin-left: 2px;
  margin-right: 2px;
}



#lorem {
  color: #F5900D;
}

.haven {
  color: #F5900D;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.con {
  font-size: 5em;
  font-weight: bolder;
  font-stretch: 200%;

}

.icon {
  background-color: #CCCCCC;
  border-radius: 100%;
  padding: 10px;
  margin-left: 3px;
  margin-right: 5px;
  font-weight: 900;
}


.marquee-wrapper {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.marquee {
  white-space: nowrap;
  display: inline-block;
  animation: scroll 10s linear infinite;
}

.rolling {
  display: inline-block;
  padding-right: 50%;
  font-size: 7em;
  font-weight: bolder;
  font-stretch: extra-expanded;
}

.rolling span {
  color: #EC8B0D;
}

@keyframes scroll {
  0% {
    transform: translateX(80%);
  }

  100% {
    transform: translateX(-100%);
  }
}





.image-container {
  position: relative;
  display: inline-block;
}

.imggg {
  width: 50px;
  transition: transform 0.3s ease;
  margin-left: 4.5em;
  margin-top: 2.2em;
  position: absolute;
  z-index: 2;
}

.imggg:hover {
  transform: rotate(-45deg);
}

.spin-image {
  z-index: 1;
  width: 200px;
  animation: spin 4s linear infinite;
  position: relative;
  margin-top: -2em;
  margin-right: -2em;

}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.agency {
  background-color: #E9E9E9;
  color: black;
  font-weight: bolder;
  text-align: center;
  margin-top: 7em;
  width: 90%;
}

#contain {
  position: relative;
  background-image: url("./assets/Images/Section\ 66.png");
  background-position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  overflow: hidden;
  height: 40vh;
}

#contain:hover {
  .agency {
    visibility: hidden;
  }
}

#containe:hover {
  .agency {
    visibility: hidden;
  }
}

#con:hover {
  .agency {
    visibility: hidden;
  }
}



#image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

#middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 64%;
  left: 50%;
  height: 45vh;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%)
}

#contain:hover .image {
  opacity: 0.3;
}

#contain:hover .middle {
  opacity: 1;
}

#text {
  background-color: rgba(206, 206, 206, 0.5);
  color: black;
  font-size: 16px;
  padding: 10px 30px;
}

#containe {
  position: relative;
  background-image: url("./assets/Images/Section\ 666.png");
  background-position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 40vh;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  overflow: hidden;
}

#imag {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

#middl {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 38vh;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%)
}

#containe:hover .imag {
  opacity: 0.3;
}

#containe:hover .middl {
  opacity: 1;
}

#tex {
  background-color: rgba(206, 206, 206, 0.5);
  color: black;
  font-size: 16px;
  padding: 10px 30px;
}

#con {
  position: relative;
  background-image: url("./assets/Images/Section\ 6.png");
  background-position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  overflow: hidden;
  height: 40vh;
}



.porthe {
  background-color: #E9E9E9;
  position: relative;
  background-image: url("./assets/Images/Frame\ 136.png");
  background-position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  overflow: hidden;
}

#background {
  position: relative;
  background-color: #F5F5F5;
  z-index: 1;
  margin-top: -6em;
  margin-bottom: -6em;
}

#ima {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

#midd {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 58%;
  left: 50%;
  height: 40vh;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%)
}

#con:hover .ima {
  opacity: 0.3;
}

#con:hover .midd {
  opacity: 1;
}

#te {
  background-color: rgba(206, 206, 206, 0.5);
  color: black;
  font-size: 16px;
  padding: 10px 30px;
}


#hoverr {
  background-color: #F4F4F4;
  color: black;
  transition: background-color 0.5s, color 0.5s, width 4s, height 10s;
  border-right: white;
  border-left: #EC8B0D;
  /* border: #EC8B0D; */
  border-style: solid;
  border-left-color: #EC8B0D;
  border-top: #E9E9E9;
  border-bottom: white;
  border-width: 4px;
}

#hoverr:hover {
  background-color: #EC8B0D;
  color: white;

}

#noFill{
  visibility: hidden;
  margin-right:-5em ;
}

#explore:hover {
  

  #fill{
  visibility: hidden;
  }

  #noFill{
    visibility: visible;
  }

  #two {
    text-decoration: underline
  }

  #three {
    background-color: black;
    color: white;
    transform: rotate(0deg);

  }


}

#three {
  background-color: #E9E9E9;
  color: grey;
  border-radius: 50%;
  transform: rotate(45deg);

}

#ReadMore {
  transform: rotate(45deg);
  /* margin-left: 2px; */
}

.eee {
  color: black;
}

#pp:hover {

  .eee {
    color: #EC8B0D;
  }

  #ReadMore {
    transform: rotate(0deg);
    margin-left: 7px;
    color: #EC8B0D;
  }
}

.right {
  transform: rotate(45deg);
  margin-left: 5px;
}

.span:hover {

  .mor {
    color: #EC8B0D;
  }

  .right {
    transform: rotate(0deg);
    color: #EC8B0D;
  }
}

.link {
  border: #EC8B0D;
  border-style: solid;
}

.arrow {
  transform: rotate(45deg);

}

.link:hover {
  .here {
    color: #EC8B0D;
  }

  .arrow {
    transform: rotate(0deg);
  }
}

.solid {
  transform: rotate(45deg);
  font-size: 5em;

}


#pink:hover .solid {
  transform: rotate(0deg);
  opacity: 0;
  /* Fades out the element */
  transition: transform 2s ease, opacity 2s ease;
  /* Adjust the timing for smooth transition */
}


#wooo {
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}




@media (max-width: 768px) {
  #New {
    min-width: 600px;
  }

  #second {
    max-height: 0vh;
    
  }
}

@media (max-width: 576px) {
  #New {
    min-width: 600px;
  }

  #second {
    max-height: 0vh;
    
  }
}

@media (max-width: 768px) {
  #abc {
    min-width: 400px;
  }

}

@media (max-width: 576px) {
  #abc {
    min-width: 400px;
  }
}

@media (max-width: 768px) {
  #bbb {
    min-width: 400px;
  }

}

@media (max-width: 576px) {
  #bbb {
    min-width: 400px;
  }
}

@media (max-width: 768px) {
  #conta {
    min-width: 400px;
  }

  #solution{

    margin-right: "1.5em";
  }

  #para{
    font-size: 4em;
  }
  
  
}

@media (max-width: 576px) {
  #conta {
    min-width: 400px;
  }

  #para{
    font-size: 4em;
  }
}

#para{
  line-height: 80%
}


#butt {
  padding: 0.8em 1.8em;
  position: relative;
  overflow: hidden;
  background-color: #F5900D;
  text-align: center;
  font-size: 16px;
  transition: .3s;
  z-index: 1;
  font-family: inherit;
  color: white;
  margin-top: 2em;
  margin-bottom: 4em;
  font-weight: 700;
  padding: 1em;
 }
 
 #butt::before {
  content: '';
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: black;
  
  transition: .5s ease;
  display: block;
  z-index: -1;
 }
 
 #butt:hover::before {
  width: 100%;
 }
 
 #butt:hover {
  color: #F5900D;
 }
 

#viewWork {
  padding: 0.8em 1.8em;
  position: relative;
  overflow: hidden;
  background-color: #E9E9E9;
  text-align: center;
  font-size: 16px;
  transition: .3s;
  z-index: 1;
  font-family: inherit;
  color: black;
  margin-top: 2em;
  margin-bottom: 4em;
  font-weight: 700;
  padding: 1em;
 }
 
 #viewWork::before {
  content: '';
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: black;
  
  transition: .5s ease;
  display: block;
  z-index: -1;
 }
 
 #viewWork:hover::before {
  width: 100%;
 }
 
 #viewWork:hover {
  color: white;
 }

 
 